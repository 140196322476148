import { createTheme, colors } from '@mui/material';
import shadows from './shadows';
import typography from './typography';
import BreakPoints from './breakPoints';

const theme = createTheme({
    breakpoints: {
        values: BreakPoints,
    },
    palette: {
        primary: {
            main: '#12667b', // Default coral
            50: '#ebfffe',
            100: '#cdfeff',
            200: '#a1faff',
            300: '#61f4ff',
            400: '#17e3f7',
            500: '#03c5dd',
            600: '#019eba',
            700: '#077d96',
            900: '#00afad',
            950: '#053747',
        },
        secondary: {
            main: '#d4af36', // Default coral
            50: '#faf9ec',
            100: '#f4f0ce',
            200: '#ebdf9d',
            300: '#dfc864',
            500: '#c49b2e',
            600: '#aa7a24',
            700: '#88591f',
            800: '#724921',
            900: '#623e21',
            950: '#382010',
        },
        tertiary: {
            main: '#ebe2ca', // Default coral
            10: '#fcfbf7',
            20: '#f9f7f0',
            30: '#f7f2e8',
            40: '#f4eee1',
            50: '#f1ead9',
            60: '#efe6d1',
            70: '#ebe2ca',
            90: '#e6d9bb',
            100: '#e4d4b3',
        },
        gray: {
            main: '#454545', // Default coral
            50: '#f6f6f6',
            100: '#e7e7e7',
            200: '#d1d1d1',
            300: '#b0b0b0',
            400: '#888888',
            500: '#6d6d6d',
            600: '#5d5d5d',
            700: '#4f4f4f',
            900: '#333333',
            950: '#262626',
        },
    },
    shadows,
    typography,
});

export default theme;
