export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_LOGIN_SUCCESS = 'ACCOUNT_LOGIN_SUCCESS';
export const ACCOUNT_LOGIN_ERROR = 'ACCOUNT_LOGIN_ERROR';
export const ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT';

import { post } from 'utils/fetchRequest';

export const login = async (dispatch, data) => {
    try {
        const result = await post('auth', data);

        if (result.code === 200) dispatch({ type: LOGIN_SUCCESS, data: result.data });
        else dispatch({ type: LOGIN_ERROR });

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const logout = (dispatch) => {
    dispatch({ type: LOGOUT });
};

export const changePassword = async (data) => {
    try {
        const result = await post('auth/changepassword', data);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const loginAccount = async (dispatch, data) => {
    try {
        const result = await post('auth/account', data);

        if (result.code === 200) dispatch({ type: ACCOUNT_LOGIN_SUCCESS, data: result.data });
        else dispatch({ type: ACCOUNT_LOGIN_ERROR });

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const logoutAccount = (dispatch) => {
    dispatch({ type: ACCOUNT_LOGOUT });
};
