import combineReducers from 'react-combine-reducers';
import auth from './auth';
import pages from './pages';
import system from './system';

// import user from './user';

const [reducer, initialState] = combineReducers({
    auth,
    pages,
    system,
    // user,
});

export { reducer, initialState };
