import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import mnjson from 'i18/translations/mn.json';
import enjson from 'i18/translations/en.json';

i18n.use(LanguageDetector) // detect user language
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: enjson,
            },
            mn: {
                translation: mnjson,
            },
            // Add more languages as needed
        },
        lng: 'mn', // default language
        fallbackLng: 'mn',
        debug: true,
        detection: {
            order: ['cookie', 'localStorage', 'navigator'],
            caches: ['cookie', 'localStorage'],
        },
        interpolation: {
            escapeValue: false, // react already escapes by default
        },
    });

// i18n.changeLanguage('mn');
export default i18n;
