const typography = {
    fontFamily: ['Montserrat, sans-serif', `Philosopher, sans-serif`].join(','),

    h61: {
        fontSize: 61,
        fontWeight: 'bold',
        // fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.24px',
    },
    h30: {
        fontSize: 30,
        // fontWeight: 'bold',
        // fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.24px',
    },
    h28: {
        fontSize: 28,
        fontWeight: 'bold',
        // fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.24px',
    },
    h26: {
        fontSize: 26,
        fontWeight: 'bold',
        // fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.06px',
    },
    h24: {
        fontSize: 24,
        fontWeight: 'bold',
        letterSpacing: '-0.05px',
        // fontFamily: 'Roboto, sans-serif',
    },
    h22: {
        fontSize: 22,
        fontWeight: 'bold',
        letterSpacing: '-0.05px',
        // fontFamily: 'Roboto, sans-serif',
    },
    h20: {
        fontSize: 20,
        fontWeight: 'bold',
        letterSpacing: '-0.05px',
        // fontFamily: 'Roboto, sans-serif',
    },
    h18: {
        fontSize: 18,
        fontWeight: 'bold',
        letterSpacing: '-0.05px',
        // fontFamily: 'Roboto, sans-serif',
    },
    h16: {
        fontSize: 16,
        letterSpacing: '-0.05px',
        // fontFamily: 'Roboto, sans-serif',
    },
    body20: {
        fontSize: 20,
        fontWeight: 400,
        // fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
    },
    body18: {
        fontSize: 18,
        fontWeight: 400,
        // fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
    },
    body16: {
        fontSize: 16,
        fontWeight: 400,
        // fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
    },
    body14: {
        fontSize: 14,
        fontWeight: 400,
        // fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
    },
    body13: {
        fontSize: 13,
        fontWeight: 400,
        // fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
    },
    body12: {
        fontSize: 12,
        fontWeight: 400,
        // fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
    },
    body10: {
        fontSize: 10,
        fontWeight: 400,
        // fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
    },
    body9: {
        fontSize: 9,
        fontWeight: 400,
        // fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
    },
    body8: {
        fontSize: 8,
        fontWeight: 400,
        // fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
    },
    body6: {
        fontSize: 6,
        fontWeight: 400,
        // fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
    },
};

export default typography;
