/* eslint-disable no-case-declarations */
import { CHANGE_LANG, SHOW_DEVELOP, SET_ALERT, SHOW_LOADING } from '../actions/system';
import Cookies from 'js-cookie';

let lang = 'mn';
if (typeof window !== 'undefined') {
    const chosenLang = Cookies.get('chosen_lang');
    if (chosenLang) lang = chosenLang;
}

const initialState = {
    lang: lang,
    showDevelop: false,
    showLoading: false,
    alert: { isShow: false, type: 'success', message: '' },
};

const reducer = (state, action) => {
    switch (action.type) {
        case CHANGE_LANG:
            return { ...state, lang: action.data };
        case SHOW_DEVELOP:
            return { ...state, showDevelop: action.data };
        case SET_ALERT:
            return { ...state, alert: action.data };
        case SHOW_LOADING:
            return { ...state, showLoading: action.data };
        default:
            return state;
    }
};

export default [reducer, initialState];
