import React, { useContext } from 'react';

import { GlobalContext } from 'context';
import LoadingDialog from 'components/global/LoadingDialog';

export default function MainLayout({ children }) {
    const { state, dispatch } = useContext(GlobalContext);

    return (
        <>
            <div>
                <main>
                    <div>{children}</div>
                </main>
            </div>

            {state.system.showLoading && <LoadingDialog open={state.system.showLoading} />}
        </>
    );
}
