import Cookies from 'js-cookie';
import {
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    ACCOUNT_LOGIN_SUCCESS,
    ACCOUNT_LOGIN_ERROR,
    ACCOUNT_LOGOUT,
} from 'context/actions/auth';

let user;
if (process.browser) {
    user = JSON.parse(localStorage.getItem('user'));
}

let account;
if (process.browser) {
    account = JSON.parse(localStorage.getItem('account'));
}

const initialState = {};

if (user)
    Object.assign(initialState, {
        loggedIn: true,
        user,
    });

if (account)
    Object.assign(initialState, {
        accountLoggedIn: true,
        account,
    });

const reducer = (state, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            Cookies.set('auth_token', action.data.token, { expires: 7 });
            localStorage.setItem('user', JSON.stringify(action.data));
            return {
                ...state,
                loggedIn: true,
                user: action.data,
            };
        case LOGIN_ERROR:
            localStorage.clear('user');
            Cookies.remove('auth_token');
            return { ...state, loggedIn: false, user: null };
        case LOGOUT:
            localStorage.clear('user');
            Cookies.remove('auth_token');
            return { ...state, loggedIn: false, user: null };
        case ACCOUNT_LOGIN_SUCCESS:
            Cookies.set('account_auth_token', action.data.token, { expires: 7 });
            localStorage.setItem('account', JSON.stringify(action.data));
            return { ...state, accountLoggedIn: true, account: action.data };
        case ACCOUNT_LOGIN_ERROR:
            localStorage.clear('account');
            Cookies.remove('account_auth_token');
            return { ...state, accountLoggedIn: false, account: null };
        case ACCOUNT_LOGOUT:
            localStorage.clear('account');
            Cookies.remove('account_auth_token');
            return { ...state, accountLoggedIn: false, account: null };

        default:
            return state;
    }
};

const authReducer = [reducer, initialState];

export default authReducer;
