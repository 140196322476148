import React, { useEffect, useState, forwardRef } from 'react';
import {
    Box,
    Dialog,
    Typography,
    Button,
    Tabs,
    Tab,
    FormControlLabel,
    Switch,
} from '@mui/material';
import Image from 'next/image';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const LoadingDialog = ({ open, handleClose }) => {
    const { t } = useTranslation();
    return (
        <Dialog
            onClose={handleClose}
            open={open}
            // classes={{
            //     paper: {
            //         backgroundColor: 'red',
            //     },
            // }}
            sx={{
                '& .MuiPaper-root': {
                    backgroundColor: '#00000000', // Custom background color
                },
                backgroundColor: '#00000000',
            }}
            // sx={{ padding: 40 }}
        >
            <Image
                width={50}
                height={50} // Specify the height here
                // height={48}
                src={'/global/loading.gif'}
                // src={i18n.language === 'mn' ? require('public/LogoFull.png') : require('public/LogoFull.png')}
                // src={require('public/cover.png')}
                alt=""
                style={{ objectFit: 'cover' }}
            />
        </Dialog>
    );
};

export default LoadingDialog;
