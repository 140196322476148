import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import Head from 'next/head';
import Router from 'next/router';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import theme from '../constants/theme';
import { ContextState } from '/context';
import MainLayout from '/layouts/MainLayout';
import '../styles/globals.css';
import '../styles/tailwind.css';
import i18n from 'i18next';
import '/i18n';
import { appWithTranslation } from 'react-i18next';
import { CHANGE_LANG } from 'context/actions/system';

import ProgressBar from '@badrap/bar-of-progress';

const progress = new ProgressBar({
    size: 3,
    color: '#373A46',
    className: 'bar-of-progress',
    delay: 100,
});

Router.events.on('routeChangeStart', progress.start);
Router.events.on('routeChangeComplete', progress.finish);
Router.events.on('routeChangeError', progress.finish);

function MyApp(props) {
    const { Component, pageProps } = props;

    const Layout = Component.Layout || MainLayout;

    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }

        if (typeof window !== 'undefined') {
            const chosenLang = Cookies.get('chosen_lang');

            i18n.changeLanguage(chosenLang ? chosenLang : 'mn');
        }
    }, []);

    return (
        <React.Fragment>
            <Head>
                <title>Alfa mining</title>
                <link rel="shortcut icon" href="/Logo.png" />
                <meta charSet="utf-8" />
                <meta property="og:title" content="Alfa mining" key="title" />
                <meta property="og:image" content="/Logo.png" key="ogimage" />
            </Head>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ContextState>
                    <Layout>
                        <Component {...pageProps} />
                    </Layout>
                </ContextState>
            </ThemeProvider>
        </React.Fragment>
    );
}

MyApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    pageProps: PropTypes.object.isRequired,
};

export default MyApp;
