/* eslint-disable no-case-declarations */
import { GET_PAGES } from '../actions/pages';

const initialState = { list: [] };

const reducer = (state, action) => {
    switch (action.type) {
        case GET_PAGES:
            return {
                list: action.data,
            };
        default:
            return state;
    }
};

export default [reducer, initialState];
